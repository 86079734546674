import { TrackedButton, TrackingButtonProps } from '../../..';
import DownloadOutlined from '@mui/icons-material/DownloadOutlined';
import {
  useSnackbar as useNotistack,
  OptionsObject,
  SnackbarMessage,
  SnackbarKey,
} from '@automata/rexports/notistack';
import { getErrorMessage, logger } from '@automata/utils';
import { forwardRef } from 'react';

// todo: move to more appropriate location
export type SnackbarOptions = OptionsObject;
export const useSnackbar = useNotistack;

export interface DownloadButtonProps extends TrackingButtonProps {
  children?: React.ReactNode;
  fetchDownloadUrl: () => Promise<string>;
}

export const clickHandler = async (
  fetchDownloadUrl: () => Promise<string>,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey
) => {
  try {
    const downloadUrl = await fetchDownloadUrl();
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (e) {
    logger.error(getErrorMessage(e, 'error fetching download url'));
    enqueueSnackbar('Exporting data unsuccessful, please try again', {
      variant: 'error',
    });
  }
};

export const DownloadButton = forwardRef<
  HTMLButtonElement,
  DownloadButtonProps
>(function Forwarded(props, ref) {
  const { children = 'Download', fetchDownloadUrl, ...buttonProps } = props;
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    clickHandler(fetchDownloadUrl, enqueueSnackbar);
  };

  return (
    <TrackedButton
      ref={ref}
      endIcon={<DownloadOutlined aria-label="download icon" />}
      onClick={handleClick}
      variant="outlined"
      {...buttonProps}
    >
      {children}
    </TrackedButton>
  );
});
