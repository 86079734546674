import { forwardRef } from 'react';
import { TrackedButton, TrackingButtonProps } from '../../..';
import ArrowCircleLeftOutlined from '@mui/icons-material/ArrowCircleLeftOutlined';
import Link from 'next/link';

export interface BackButtonProps extends TrackingButtonProps {
  children: React.ReactNode;
  href: string;
}

export const BackButton = forwardRef<HTMLButtonElement, BackButtonProps>(
  function Forwarded(props, ref) {
    const { children, href, ...trackedButtonProps } = props;

    return (
      <TrackedButton
        ref={ref}
        href={href}
        LinkComponent={Link}
        startIcon={<ArrowCircleLeftOutlined aria-label="back arrow icon" />}
        sx={{
          backgroundColor: 'grey.50',
          color: 'text.secondary',
          textTransform: 'capitalize',
        }}
        {...trackedButtonProps}
      >
        {children}
      </TrackedButton>
    );
  }
);
