import { Badge, IconButton } from '@mui/material';
import SellOutlined from '@mui/icons-material/SellOutlined';

export type TagIconButtonProps = {
  badgeCount: number;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const TagIconButton = ({
  badgeCount,
  onClick,
}: TagIconButtonProps): JSX.Element | null => {
  return (
    <IconButton
      sx={{ position: 'relative' }}
      onClick={onClick}
      aria-label="Tag Icon Button"
    >
      <Badge badgeContent={badgeCount} color="primary" data-testid="badge-icon">
        <SellOutlined />
      </Badge>
    </IconButton>
  );
};

export default TagIconButton;
